@import url(https://fonts.googleapis.com/css?family=Quicksand:300,400,500,600,700&display=swap);
:root,
body {
  margin: 0;
  height: 100%;
  overflow: hidden;
}
#root {
  margin: 0;
  height: 100%;
  overflow: auto;
}
@-webkit-keyframes fadeUp {
  0% {
    transform: translateY(500px);
    opacity: 0;
  }
  80% {
    transform: translateY(0px);
    opacity: 0.7;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeUp {
  0% {
    transform: translateY(500px);
    opacity: 0;
  }
  80% {
    transform: translateY(0px);
    opacity: 0.7;
  }
  100% {
    opacity: 1;
  }
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: default;

}

.unselectable::-moz-focus-inner {
  border: 0;
}



@-webkit-keyframes image_blur {
    0% { -webkit-filter: blur(4px); border: 10px solid #F45653;}
    50% { -webkit-filter: blur(16px); border: 20px solid #F45653;}
    100% { -webkit-filter: blur(32px); border:300px solid #F45653; }
}

@keyframes image_blur {
    0% { -webkit-filter: blur(4px); border: 10px solid #F45653;}
    50% { -webkit-filter: blur(16px); border: 20px solid #F45653;}
    100% { -webkit-filter: blur(32px); border:300px solid #F45653; }
}

.flex-anim{
    -webkit-animation: image_blur 6s infinite;
            animation: image_blur 6s infinite;
     
}

