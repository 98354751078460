@keyframes image_blur {
    0% { -webkit-filter: blur(4px); border: 10px solid #F45653;}
    50% { -webkit-filter: blur(16px); border: 20px solid #F45653;}
    100% { -webkit-filter: blur(32px); border:300px solid #F45653; }
}

.flex-anim{
    animation: image_blur 6s infinite;
     
}
