@import url("https://fonts.googleapis.com/css?family=Quicksand:300,400,500,600,700&display=swap");
:root,
body {
  margin: 0;
  height: 100%;
  overflow: hidden;
}
#root {
  margin: 0;
  height: 100%;
  overflow: auto;
}
@keyframes fadeUp {
  0% {
    transform: translateY(500px);
    opacity: 0;
  }
  80% {
    transform: translateY(0px);
    opacity: 0.7;
  }
  100% {
    opacity: 1;
  }
}
